import React                from 'react';
import PropTypes            from 'prop-types';
import { find }             from 'lodash';
import { FormattedMessage } from 'react-intl';

import { ContactContext } from '../../../providers';
import { Button }         from '../../elements/Button/Button';
import { Link }           from '../../elements/Link/Link';
import PhoneNumber        from '../../text/PhoneNumber/PhoneNumber';

function CallToAction({ showNumber, showMobileNumber }) {
  return (
    <Button as={Link} to={'/contact'}>
      <ContactContext.Consumer>
        {context => {
          const number = find(context.numbers, number => number.type === 'phone' && number.primary);
          const mobileNumber = find(context.numbers, number => number.type === 'mobile' && number.primary);
          return (
            <>
              <div><FormattedMessage id='components.callToAction.content1'/></div>
              {showNumber && <div><PhoneNumber number={number.number} callable={false}/></div>}
              {showMobileNumber && <div><PhoneNumber number={mobileNumber.number} callable={false}/></div>}
              <div><FormattedMessage id='components.callToAction.content2'/></div>
            </>
          );
        }}
      </ContactContext.Consumer>
    </Button>
  )
}

CallToAction.propTypes = {
  showNumber: PropTypes.bool,
  showMobileNumber: PropTypes.bool,
}

CallToAction.defaultProps = {
  showNumber: true,
  showMobileNumber: false,
}

export default CallToAction;
